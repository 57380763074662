import { Component, Input } from '@angular/core';

@Component({
    selector: 'list-flat',
    templateUrl: 'list-flat.component.html',
    styleUrls: ['list-flat.component.scss'],
})

export class ListFlatComponent {
    @Input() icon: string;
    @Input() svg: boolean = false;
    @Input() usp: boolean = false;
    @Input() expanded: boolean = false;
    @Input() numbered: boolean = false;
    @Input() lined: boolean = false;
    @Input() table: boolean = false;
    @Input() noMargin: boolean = false;
}
